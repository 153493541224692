import React from "react"
import Box from "./box"
import PropTypes from "prop-types"

/**
 * Badges are used to highlight text, e.g. course duration and
 * will grow based on the content. The spacing around the text is based on the
 * font-size. Round Badges get different vertical `padding` and `min-width`
 * based on the font size to allow "1:1" badges, e.g. for notification bubbles
 */
const Badge = ({ color, size, round, children, bg, ...restProps }) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        bg,
        borderRadius: round ? "full" : "md",
        color,
        display: "inline-flex",
        fontSize: size,
        fontFamily: "sans",
        fontWeight: "bold",
        justifyContent: "center",
        minWidth: round ? "1.5em" : "2em",
        px: round ? "0.75em" : "0.5em",
        py: "0.25em",
        textAlign: "center",
      }}
      {...restProps}
    >
      {children}
    </Box>
  )
}

Badge.propTypes = {
  /** Background Color */
  bg: PropTypes.string,
  /** Text Color */
  color: PropTypes.string,
  /** Font Size */
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array,
    PropTypes.string,
  ]),
  /** Round Badges get different vertical `padding` and `min-width` based on the font size to allow "square" badges, e.g. for notifications */
  round: PropTypes.bool,
}

Badge.defaultProps = {
  bg: "muted",
  color: "text",
  size: 0,
  round: false,
}

export default Badge
