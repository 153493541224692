import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useThemeUI } from "theme-ui"

import { Link } from "gatsby"
import Grid from "../../components/grid"
import Box from "../../components/box"
import Columns from "../../components/columns"
import Column from "../../components/column"
import Image from "../../components/image"
import Stack from "../../components/stack"
import Heading from "../../components/heading"
import Badge from "../../components/badge"

const NavigationItem = ({ title, to, alt, image, subtitle }) => {
  const context = useThemeUI()
  const { theme } = context
  return (
    <Box
      as={Link}
      to={to}
      activeClassName="active"
      sx={{
        bg: "muted",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignY: "center",
        width: "full",
        height: "full",
        transition: `all ${theme.transitions["main"]}`,
        "&:hover, &:focus": {
          bg: "primary",
        },
        "&.active": {
          cursor: "default",
          bg: "background",
        },
        ".nav-item-badge": {
          display: "none",
        },
        "&.active .nav-item-badge": {
          display: "inline-flex",
        },
        "&:hover .nav-item-title, &:focus .nav-item-title, &:hover .nav-item-subtitle, &:focus .nav-item-subtitle":
          {
            color: "black",
          },
        "&.active .nav-item-title": {
          color: "text",
        },
        "&.active .nav-item-subtitle": {
          color: "whisper",
        },
        ".nav-item-image": {
          transition: `all ${theme.transitions.main}`,
        },
        "&:hover .nav-item-image, &:focus .nav-item-image": {
          transform: "scale(1.1)",
        },
        "&.active .nav-item-image, &:focus .nav-item-image": {
          transform: "none",
        },
      }}
    >
      <Columns space={0} alignY="center" flex="auto">
        {image && (
          <Column width="30%">
            <Box
              py={3}
              pl={3}
              sx={{ position: "relative", width: "100%", overflow: "hidden" }}
            >
              <Image className="nav-item-image" alt={alt || ""} image={image} />
            </Box>
          </Column>
        )}
        <Column>
          <Box p={6}>
            <Stack space={2}>
              <Stack space={1}>
                {subtitle !== false && (
                  <Heading className="nav-item-subtitle" color="whisper">
                    Hölderlin liebt…
                  </Heading>
                )}
                <Heading as="h2" className="nav-item-title" level={5}>
                  {title}
                </Heading>
              </Stack>
              <Badge
                className="nav-item-badge"
                color="background"
                bg="contrast"
              >
                Sie sind hier
              </Badge>
            </Stack>
          </Box>
        </Column>
      </Columns>
    </Box>
  )
}

const HoelderlinLiebtNavigation = () => {
  const data = useStaticQuery(graphql`
    query HoelderlinLiebtNavigationQuery {
      aussicht: file(
        relativePath: { eq: "hoelderlin-liebt/titel-aussicht.png" }
      ) {
        ...hyperCardImage
      }
      haelfte: file(
        relativePath: { eq: "hoelderlin-liebt/titel-haelfte.png" }
      ) {
        ...hyperCardImage
      }
      fakten: file(relativePath: { eq: "hoelderlin-liebt/titel-fakten.jpg" }) {
        ...hyperCardImage
      }
      freiheit: file(
        relativePath: { eq: "hoelderlin-liebt/titel-freiheit.png" }
      ) {
        ...hyperCardImage
      }
      susette: file(
        relativePath: { eq: "hoelderlin-liebt/titel-susette.png" }
      ) {
        ...hyperCardImage
      }
      hoelderlin: file(
        relativePath: { eq: "hoelderlin-liebt/titel-hoelderlin.png" }
      ) {
        ...hyperCardImage
      }
      namen: file(relativePath: { eq: "hoelderlin-liebt/titel-namen.png" }) {
        ...hyperCardImage
      }
      menschen: file(
        relativePath: { eq: "hoelderlin-liebt/titel-menschen.png" }
      ) {
        ...hyperCardImage
      }
      neckar: file(relativePath: { eq: "hoelderlin-liebt/titel-neckar.png" }) {
        ...hyperCardImage
      }
      pflanzen: file(
        relativePath: { eq: "hoelderlin-liebt/titel-pflanzen.png" }
      ) {
        ...hyperCardImage
      }
      turm: file(relativePath: { eq: "hoelderlin-liebt/titel-turm.png" }) {
        ...hyperCardImage
      }
      wandern: file(
        relativePath: { eq: "hoelderlin-liebt/titel-wandern.png" }
      ) {
        ...hyperCardImage
      }
      woerter: file(
        relativePath: { eq: "hoelderlin-liebt/titel-woerter.png" }
      ) {
        ...hyperCardImage
      }
    }
  `)

  return (
    <Stack>
      <Stack space={6}>
        <Heading level={4}>Hölderlin kennenlernen</Heading>
        <Grid columns={[1, 1, 2]} space={6}>
          <NavigationItem
            subtitle={false}
            title="Das ist Friedrich Hölderlin"
            image={data.hoelderlin.childImageSharp.gatsbyImageData}
            to="/sonderausstellungen/hoelderlin-liebt/das-ist-hoelderlin"
          />
          <NavigationItem
            subtitle={false}
            title="Hölderlin wohnt in einem Turm"
            image={data.turm.childImageSharp.gatsbyImageData}
            to="/sonderausstellungen/hoelderlin-liebt/turm"
          />
          <NavigationItem
            subtitle={false}
            title="12 Fakten über Hölderlin in Gebärdensprache"
            image={data.fakten.childImageSharp.gatsbyImageData}
            to="/sonderausstellungen/hoelderlin-liebt/12-fakten"
          />
          <NavigationItem
            subtitle={false}
            title="Hölderlins Hälften des Lebens"
            image={data.haelfte.childImageSharp.gatsbyImageData}
            to="/sonderausstellungen/hoelderlin-liebt/haelfte-des-lebens"
          />
        </Grid>
      </Stack>
      <Stack space={6}>
        <Heading level={4}>Friedrich Hölderlin liebt…</Heading>
        <Grid columns={[1, 1, 2]} space={6}>
          <NavigationItem
            title="die Menschen"
            image={data.menschen.childImageSharp.gatsbyImageData}
            to="/sonderausstellungen/hoelderlin-liebt/menschen"
          />
          <NavigationItem
            title="den Neckar"
            image={data.neckar.childImageSharp.gatsbyImageData}
            to="/sonderausstellungen/hoelderlin-liebt/neckar"
          />
          <NavigationItem
            title="die Wörter"
            image={data.woerter.childImageSharp.gatsbyImageData}
            to="/sonderausstellungen/hoelderlin-liebt/woerter"
          />
          <NavigationItem
            title="die Pflanzen"
            image={data.pflanzen.childImageSharp.gatsbyImageData}
            to="/sonderausstellungen/hoelderlin-liebt/pflanzen"
          />
          <NavigationItem
            title="die Freiheit"
            image={data.freiheit.childImageSharp.gatsbyImageData}
            to="/sonderausstellungen/hoelderlin-liebt/freiheit"
          />
          <NavigationItem
            title="das Wandern"
            image={data.wandern.childImageSharp.gatsbyImageData}
            to="/sonderausstellungen/hoelderlin-liebt/wandern"
          />
          <NavigationItem
            title="die Namen"
            image={data.namen.childImageSharp.gatsbyImageData}
            to="/sonderausstellungen/hoelderlin-liebt/namen"
          />
          <NavigationItem
            title="die Aussicht"
            image={data.aussicht.childImageSharp.gatsbyImageData}
            to="/sonderausstellungen/hoelderlin-liebt/aussicht"
          />
          <NavigationItem
            title="Susette Gontard"
            image={data.susette.childImageSharp.gatsbyImageData}
            to="/sonderausstellungen/hoelderlin-liebt/susette"
          />
        </Grid>
      </Stack>
    </Stack>
  )
}

export default HoelderlinLiebtNavigation
