import React from "react"
import Stack from "./stack"

const SectionSpacing = (props) => {
  const { children, ...restProps } = props
  return (
    <Stack space={[24, 24, 32, 48]} {...restProps}>
      {children}
    </Stack>
  )
}

export default SectionSpacing
